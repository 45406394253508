<template>
  <b-overlay
    :show="cargando"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <b-card>
      <b-row class="mt-0">

        <!-- TIPO -->
        <b-col
          cols="12"
          lg="4"
        >
          <b-form-group
            label="Tipo de envío *"
            label-for="tipo"
          >
            <v-select
              input-id="tipo"
              v-model="comunicacion.tipo"
              placeholder="Selecciona un tipo..."
              label="texto"
              :options="optionsTipos"
              :reduce="option => option.id"
              :clearable="false"
              @input="changeTipoEnvio()"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :class="v$.comunicacion.tipo.$error === true
                ? 'border-danger rounded'
                : ''"
            />
            <div
              v-if="v$.comunicacion.tipo.$error"
              id="tipoInfo"
              class="text-danger text-right"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.comunicacion.tipo.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div>
          </b-form-group>
        </b-col>

        <!-- CURSOS -->
        <b-col
          cols="12"
          lg="4"
        >
          <b-form-group
            label="Curso"
            label-for="curso"
          >
            <b-overlay
              :show="cargandoCursos"
              spinner-variant="primary"
              variant="semi-dark"
            >
              <v-select
                input-id="id_curso"
                v-model="comunicacion.cursos"
                :placeholder="comunicacion.tipo == 1
                  ? 'Selecciona uno o varios cursos...'
                  : 'Selecciona un curso...'"
                label="texto"
                :options="optionsCursos"
                :reduce="option => option.id"
                :clearable="false"
                :multiple="comunicacion.tipo == 1"
                :closeOnSelect="comunicacion.tipo == 2"
                :disabled="comunicacion.tipo == null"
                @input="changeCurso()"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :class="v$.comunicacion.cursos.$error === true
                ? 'border-danger rounded'
                : ''"
              />
              <div
                v-if="v$.comunicacion.cursos.$error"
                id="cursosInfo"
                class="text-danger text-right"
                style="font-size: 0.857rem;"
              >
                <p v-for="error of v$.comunicacion.cursos.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </div>
            </b-overlay>
          </b-form-group>
        </b-col>

        <!-- ESTUDIANTES -->
        <b-col
          cols="12"
          lg="4"
        >
          <b-form-group
            v-if="comunicacion.tipo == 2"
            label="Estudiantes"
            label-for="alumnos"
          >
            <b-overlay
              :show="cargandoEstudiantes"
              spinner-variant="primary"
              variant="semi-dark"
            >
              <v-select
                input-id="alumnos"
                v-model="comunicacion.alumnos"
                placeholder="Selecciona uno o varios estudiantes..."
                label="texto"
                :options="optionsEstudiantes"
                :reduce="option => option.id"
                :clearable="false"
                multiple
                :closeOnSelect="false"
                :disabled="comunicacion.cursos === null ? true : comunicacion.cursos.length === 0"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :class="v$.comunicacion.alumnos.$error === true
                ? 'border-danger rounded'
                : ''"
              />
              <div
                v-if="v$.comunicacion.alumnos.$error"
                id="alumnosInfo"
                class="text-danger text-right"
                style="font-size: 0.857rem;"
              >
                <p v-for="error of v$.comunicacion.alumnos.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </div>
            </b-overlay>
          </b-form-group>
        </b-col>

        <!-- FECHA -->
        <b-col
          v-show="false"
          cols="12"
          lg="4"
        >
          <b-form-group
            label="Fecha de envío*"
            label-for="fecha_envio"
          >
            <calendario
              id="fecha_envio"
              tipo="hastaFechaFinal"
              :fecha="comunicacion.fecha_envio"
              :state="false"
              @changeCalendario="changeCalendario"
            />
              <!-- :state="v$.comunicacion.fecha_envio.$error" -->
              <!-- Mensajes Error Validación -->
              <!-- <b-form-invalid-feedback
                v-if="v$.comunicacion.fecha_envio.$error"
                id="fecha_envioInfo"
                class="text-right"
              >
                <p v-for="error of v$.comunicacion.fecha_envio.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </b-form-invalid-feedback> -->
          </b-form-group>
        </b-col>

        <!-- HORA -->
        <b-col
          v-show="false"
          cols="12"
          lg="4"
        >
          <b-form-group
            label="Hora de envío *"
            label-for="hora_envio"
          >
            <hora
              id="hora_envio"
              :showBtn="true"
              :hora.sync="comunicacion.hora_envio"
              :state="false"
              @changeHora="changeHora"
            />
            <!-- :state="v$.comunicacion.hora_envio.$error" -->

            <!-- Mensajes Error Validación -->
            <!-- <div
              v-if="v$.comunicacion.hora_envio.$error"
              id="hora_envioInfo"
              class="text-danger text-right"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.comunicacion.hora_envio.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div> -->
          </b-form-group>
        </b-col>

        <!-- TÍTULO -->
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            label="Título *"
            label-for="titulo"
          >
            <b-form-input
              id="titulo"
              placeholder="Ingresa los título de la comunicación"
              v-model="comunicacion.titulo"
              :state="v$.comunicacion.titulo.$error === true
                  ? false
                  : null"
              @blur.native="v$.comunicacion.titulo.$touch"
            />
            <b-form-invalid-feedback
              v-if="v$.comunicacion.titulo.$error"
              id="tituloInfo"
              class="text-right"
            >
              <p v-for="error of v$.comunicacion.titulo.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <!-- DETALLE -->
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            label="Detalle *"
            label-for="detalle"
          >
            <b-form-textarea
              id="detalle"
              placeholder="Ingresa el detalle de la comunicación"
              v-model="comunicacion.detalle"
              :state="v$.comunicacion.detalle.$error === true
                  ? false
                  : null"
              @blur.native="v$.comunicacion.detalle.$touch"
            />
            <b-form-invalid-feedback
              v-if="v$.comunicacion.detalle.$error"
              id="detalleInfo"
              class="text-right"
            >
              <p v-for="error of v$.comunicacion.detalle.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

      </b-row>

      <!-- FOOTER BTNS -->
      <b-row>
        <b-col
          cols="12"
          md="10"
        >
        </b-col>
        <b-col
          cols="12"
          md="2"
        >
          <btnSubmit
            class="float-right"
            variant="primary"
            :btnText="btnSubmit"
            :modulo="nombre_permiso"
            @processBtn="submitOption"
          />
        </b-col>
      </b-row>

    </b-card>
  </b-overlay>
</template>

<script>

// ETIQUETAS
import {
  BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback,
  BMedia, BButton, BAvatar, BOverlay, BFormDatepicker, BFormTimepicker,
  BFormTextarea, BAlert, BModal, BCard, BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

// AXIOS
import { mapGetters, mapActions } from 'vuex'

// FORMATOS
import { formatos } from '@core/mixins/ui/formatos'

// VALIDACIONES
import useVuelidate from '@vuelidate/core'
import { required, helpers, maxLength} from '@vuelidate/validators'

// COMPONENTES RECICLADOS
import calendario from '../../components/Form/calendario.vue'
import hora from '../../components/Form/hora.vue'
import btnSubmit from '../../components/Form/btnSubmit.vue'

export default {
  components: {
    // ETIQUETAS
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BMedia,
    BButton,
    BAvatar,
    BOverlay,
    BFormDatepicker,
    BFormTimepicker,
    BFormTextarea,
    BAlert,
    BModal,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    vSelect,

    // COMPONENTES RECICLADOS
    calendario,
    hora,
    btnSubmit,
  },
  mixins: [formatos],
  validations() {
    return {
      comunicacion: {
        tipo: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        // fecha_envio: {
        //   // $autoDirty: true,
        //   required: helpers.withMessage('El campo es requerido.', required),
        // },
        titulo: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 50 caracteres.'
            , maxLength(50)),
        },
        detalle: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 250 caracteres.'
            , maxLength(250)),
        },
        cursos: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        alumnos: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },

      }
    }
  },
  data() {
    return {
      nombre_permiso: 'comunicaciones',
      time: {
        time: true,
        timePattern: ['h', 'm'],
      },
      cargandoCursos: false,
      cargandoEstudiantes: false,
      cargando: false,
      optionsCursos: [],
      optionsEstudiantes: [],

      optionsTipos: [
        {
          'id' : 1,
          'texto' : 'Por Curso (s)',
        },
        {
          'id' : 2,
          'texto' : 'Por Alumno (s) de un curso',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getCursos: 'cursos/getCursos',
      getAlumnos: 'personas/getAlumnos',
    }),
  },
  props: {
    btnSubmit: {
      type: String,
      Default: 'Guardar',
    },
    comunicacion: {
      type: Object,
      required: true,
    },
  },
  watch: {
    getAlumnos(val) {
      this.setAlumnos(val)
    }
  },
  mounted() {
    this.setHora()
    this.cargarCursosJefatura()
  },
  methods: {
    ...mapActions({
      fetchCursosJefatura: 'cursos/fetchCursosJefatura',
      fetchCursosEstablecimiento: 'cursos/fetchCursosEstablecimiento',
      fetchAlumnos: 'personas/fetchAlumnos',
      attempt: 'auth/attempt',
    }),
    setHora() {
      const Xmas95 = new Date();
      this.comunicacion.hora_envio = Xmas95.getHours()+':'+ Xmas95.getMinutes()
    },
    changeTipoEnvio() {
      this.comunicacion.alumnos = []
      this.cargarCursos()
    },
    changeCurso() {
      if (this.comunicacion.tipo === 1 && this.comunicacion.cursos.length > 0) {
        this.cargarAlumnosCursos()
      } else if (this.comunicacion.tipo === 2 && this.comunicacion.cursos != null) {
        this.cargarAlumnos()
      }
    },
    cargarCursosJefatura() {
      this.fetchCursosJefatura().then(() => {
        this.setCursos();
      })
    },
    cargarCursos () {
      this.cargandoCursos = true
      this.comunicacion.cursos = []
      this.optionsCursos = []
      if (this.getCursos.length == 0) {
        this.fetchCursosEstablecimiento(this.getUser.id_establecimiento).then(() => {
          this.setCursos();
        })
      } else {
        this.setCursos();
      }
    },
    setCursos() {
      this.optionsCursos = []
      if (typeof this.comunicacion.id === 'undefined') { // CREAR
        this.getCursos.forEach(curso => {
          const texto = `${curso.nombre} ${curso.letra}`
          this.optionsCursos.push({
            id: curso.id,
            texto,
          })
        })
        if (this.getCursos.length === 1) {
          if (this.comunicacion.tipo === 1) {
            this.comunicacion.cursos.push(this.getCursos[0].id)
            this.cargarAlumnosCursos()
          } else {
            this.comunicacion.cursos = this.getCursos[0].id
            this.cargarAlumnos()
          }
        }
      } else { // EDITAR
        const curso = this.getCursos.filter(c => c.id === this.comunicacion.id_curso)
        const texto = `${curso.nombre} ${curso.letra}`
        this.optionsCursos.push({
          id: curso.id,
          texto,
        })
      }
      if (this.getCursos.length === 1) {
        this.comunicacion.id_curso = this.getCursos[0].id
      }
      this.cargandoCursos = false
    },
    cargarAlumnosCursos() {
      this.cargandoEstudiantes = true
      this.optionsEstudiantes = []
      this.comunicacion.alumnos = []
      this.comunicacion.cursos.forEach(curso => {
        this.fetchAlumnos(curso).then(() => {})
      })
    },
    cargarAlumnos() {
      this.cargandoEstudiantes = true
      this.comunicacion.alumnos = []
      this.optionsEstudiantes = []
      this.fetchAlumnos(this.comunicacion.cursos).then(() => {})
    },
    setAlumnos(alumnos) {
      alumnos.forEach(alumno => {
        const texto = `${alumno.nombre} ${alumno.primer_apellido} - ${alumno.rut}-${alumno.dv}`
        this.optionsEstudiantes.push({
          id: alumno.id_persona_rol_alumno,
          texto,
        })
        if (this.comunicacion.tipo === 1) {
          this.comunicacion.alumnos.push(alumno.id_persona_rol_alumno)
        }
      })
      this.cargandoEstudiantes = false
    },
    submitOption() {
      this.v$.comunicacion.$touch()
      if (!this.v$.comunicacion.$invalid) {
        // * 0: Con Error, 1: Programado, 2: Enviado
        this.comunicacion.estado = 2
        this.$emit('processForm', this.comunicacion)
      }
    },
    // CALENDARIO
    changeCalendario(fecha) {
      this.comunicacion.fecha_envio = fecha
    },
    // HORA
    changeHora(hora) {
      this.comunicacion.hora_envio = hora
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
