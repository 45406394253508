var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.cargando,"spinner-variant":"primary","variant":"semi-dark"}},[_c('b-card',[_c('b-row',{staticClass:"mt-0"},[_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Tipo de envío *","label-for":"tipo"}},[_c('v-select',{class:_vm.v$.comunicacion.tipo.$error === true
              ? 'border-danger rounded'
              : '',attrs:{"input-id":"tipo","placeholder":"Selecciona un tipo...","label":"texto","options":_vm.optionsTipos,"reduce":function (option) { return option.id; },"clearable":false,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},on:{"input":function($event){return _vm.changeTipoEnvio()}},model:{value:(_vm.comunicacion.tipo),callback:function ($$v) {_vm.$set(_vm.comunicacion, "tipo", $$v)},expression:"comunicacion.tipo"}}),(_vm.v$.comunicacion.tipo.$error)?_c('div',{staticClass:"text-danger text-right",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"tipoInfo"}},_vm._l((_vm.v$.comunicacion.tipo.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Curso","label-for":"curso"}},[_c('b-overlay',{attrs:{"show":_vm.cargandoCursos,"spinner-variant":"primary","variant":"semi-dark"}},[_c('v-select',{class:_vm.v$.comunicacion.cursos.$error === true
              ? 'border-danger rounded'
              : '',attrs:{"input-id":"id_curso","placeholder":_vm.comunicacion.tipo == 1
                ? 'Selecciona uno o varios cursos...'
                : 'Selecciona un curso...',"label":"texto","options":_vm.optionsCursos,"reduce":function (option) { return option.id; },"clearable":false,"multiple":_vm.comunicacion.tipo == 1,"closeOnSelect":_vm.comunicacion.tipo == 2,"disabled":_vm.comunicacion.tipo == null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},on:{"input":function($event){return _vm.changeCurso()}},model:{value:(_vm.comunicacion.cursos),callback:function ($$v) {_vm.$set(_vm.comunicacion, "cursos", $$v)},expression:"comunicacion.cursos"}}),(_vm.v$.comunicacion.cursos.$error)?_c('div',{staticClass:"text-danger text-right",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"cursosInfo"}},_vm._l((_vm.v$.comunicacion.cursos.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[(_vm.comunicacion.tipo == 2)?_c('b-form-group',{attrs:{"label":"Estudiantes","label-for":"alumnos"}},[_c('b-overlay',{attrs:{"show":_vm.cargandoEstudiantes,"spinner-variant":"primary","variant":"semi-dark"}},[_c('v-select',{class:_vm.v$.comunicacion.alumnos.$error === true
              ? 'border-danger rounded'
              : '',attrs:{"input-id":"alumnos","placeholder":"Selecciona uno o varios estudiantes...","label":"texto","options":_vm.optionsEstudiantes,"reduce":function (option) { return option.id; },"clearable":false,"multiple":"","closeOnSelect":false,"disabled":_vm.comunicacion.cursos === null ? true : _vm.comunicacion.cursos.length === 0,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.comunicacion.alumnos),callback:function ($$v) {_vm.$set(_vm.comunicacion, "alumnos", $$v)},expression:"comunicacion.alumnos"}}),(_vm.v$.comunicacion.alumnos.$error)?_c('div',{staticClass:"text-danger text-right",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"alumnosInfo"}},_vm._l((_vm.v$.comunicacion.alumnos.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1):_vm._e()],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Fecha de envío*","label-for":"fecha_envio"}},[_c('calendario',{attrs:{"id":"fecha_envio","tipo":"hastaFechaFinal","fecha":_vm.comunicacion.fecha_envio,"state":false},on:{"changeCalendario":_vm.changeCalendario}})],1)],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Hora de envío *","label-for":"hora_envio"}},[_c('hora',{attrs:{"id":"hora_envio","showBtn":true,"hora":_vm.comunicacion.hora_envio,"state":false},on:{"update:hora":function($event){return _vm.$set(_vm.comunicacion, "hora_envio", $event)},"changeHora":_vm.changeHora}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Título *","label-for":"titulo"}},[_c('b-form-input',{attrs:{"id":"titulo","placeholder":"Ingresa los título de la comunicación","state":_vm.v$.comunicacion.titulo.$error === true
                ? false
                : null},nativeOn:{"blur":function($event){return _vm.v$.comunicacion.titulo.$touch($event)}},model:{value:(_vm.comunicacion.titulo),callback:function ($$v) {_vm.$set(_vm.comunicacion, "titulo", $$v)},expression:"comunicacion.titulo"}}),(_vm.v$.comunicacion.titulo.$error)?_c('b-form-invalid-feedback',{staticClass:"text-right",attrs:{"id":"tituloInfo"}},_vm._l((_vm.v$.comunicacion.titulo.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Detalle *","label-for":"detalle"}},[_c('b-form-textarea',{attrs:{"id":"detalle","placeholder":"Ingresa el detalle de la comunicación","state":_vm.v$.comunicacion.detalle.$error === true
                ? false
                : null},nativeOn:{"blur":function($event){return _vm.v$.comunicacion.detalle.$touch($event)}},model:{value:(_vm.comunicacion.detalle),callback:function ($$v) {_vm.$set(_vm.comunicacion, "detalle", $$v)},expression:"comunicacion.detalle"}}),(_vm.v$.comunicacion.detalle.$error)?_c('b-form-invalid-feedback',{staticClass:"text-right",attrs:{"id":"detalleInfo"}},_vm._l((_vm.v$.comunicacion.detalle.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"10"}}),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('btnSubmit',{staticClass:"float-right",attrs:{"variant":"primary","btnText":_vm.btnSubmit,"modulo":_vm.nombre_permiso},on:{"processBtn":_vm.submitOption}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }